<template>
	<div id="gmap" ref="gmap" />
</template>

<script setup>
import { Loader } from '@googlemaps/js-api-loader';
import GoogleMaps from '@becurious/googlemaps';

const config = useRuntimeConfig();

const gmap = ref(null);

let map = null;

const mapConfig = {
	zoom: 12,
	center: { lat: 52.0460475, lng: 4.8517175 },
	// prettier-ignore
	styles: [{ featureType: 'administrative', elementType: 'all', stylers: [{ visibility: 'off' }] }, { featureType: 'landscape', elementType: 'all', stylers: [ { visibility: 'simplified' }, { hue: '#0066ff' }, { saturation: 74 }, { lightness: 100 }]}, { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] }, { featureType: 'road', elementType: 'all', stylers: [{ visibility: 'simplified' }] }, { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'off' }, { weight: 0.6 }, { saturation: -85 }, { lightness: 61 }] }, { featureType: 'road.highway', elementType: 'geometry', stylers: [{ visibility: 'on' }] }, { featureType: 'road.arterial', elementType: 'all', stylers: [{ visibility: 'off' }] }, { featureType: 'road.local', elementType: 'all', stylers: [{ visibility: 'on' }] }, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'simplified' }] }, { featureType: 'water', elementType: 'all', stylers: [ { visibility: 'simplified' }, { color: '#5f94ff' }, { lightness: 26 }, { gamma: 5.86 }]}],
	draggable: true,
	mapTypeControl: false,
	zoomControl: true,
	streetViewControl: true,
	scrollwheel: true,
};

onMounted(async () => {
	const google = await new Loader({
		apiKey: config.public.maps.apiKey,
	}).load();
	map = new GoogleMaps(google, gmap.value, mapConfig);
	initializeMap();
});

const initializeMap = () => {
	map.setMarkerIcon({
		src: '/images/marker.png',
		width: 30,
		height: 42,
		anchorX: 15,
		anchorY: 21,
	});

	map.addInfoWindow(
		map.addMarker(Object.values(mapConfig.center), 'De Dijketelg'),
		'<h3>De Dijketelg</h3>' + '<p class="address">Papekopperstraatweg 2<br>3464 HL Papekop - Oudewater</p>',
	);
};
</script>
