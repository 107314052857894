<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						{{ defaults[locale].homepage.headingImageTitle }}
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					<p>
						<a onclick="_gstpln.openWidget()" href="#" class="button">{{ $t('buttonReserveNow') }}</a>
					</p>
				</div>
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>
		</heading-image>

		<main-content>
			<div class="columns column12">
				<div class="grid main-intro">
					<div class="intro-image">
						<picture>
							<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
							<source :srcset="defaults[locale].homepage.introImage" />
							<img
								:src="defaults[locale].homepage.introImage"
								:alt="defaults[locale].homepage.introImageAlt"
								loading="lazy"
							/>
						</picture>
					</div>
					<div class="intro-text">
						<h2 class="subtitle">
							{{ page.subtitle }}
						</h2>
						<h1>{{ page.title || page.header }}</h1>
						<div v-parse-links v-html="page.content" />
					</div>
				</div>
			</div>
		</main-content>

		<promoblocks :items="promoblocksData" />

		<section v-if="defaults[locale].homepage.sectionReservationsTitle" class="section-reservations">
			<div class="border-lines" />
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
			</div>
			<div class="border-lines" />
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.promotionBackgroundImageWebp" type="image/webp" />
				<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
				<img
					class="promotions-image"
					:src="defaults[locale].homepage.promotionBackgroundImage"
					loading="lazy"
				/>
			</picture>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row">
				<div class="columns column3">
					<p>
						<strong>{{ defaults[locale].website.restaurantName }}</strong>
						<br />
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
							{{ defaults[locale].website.phone }}
						</a>
						<br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
							{{ defaults[locale].website.email }}
						</a>
					</p>
				</div>
				<div class="columns column12 maps">
					<highlights-map />
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	display: flex;
	flex-flow: column nowrap;
	color: var(--primary-background-color);
	max-width: 640px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 700;
		color: var(--primary-background-color);
		font-size: 46px;
		line-height: 70px;
		margin-bottom: 40px;
		padding: 20px 0;
		text-transform: uppercase;
		letter-spacing: 3px;
		border-top: 1px solid var(--primary-background-color);
		border-bottom: 1px solid var(--primary-background-color);
	}

	:deep(> *) {
		max-width: 700px;
		margin: 0 0 10px;
	}
}

.main-intro {
	margin: auto;
	grid-template-columns: 6fr 6fr;
	gap: 80px;

	.intro-text {
		padding: 70px 100px 0 20px;
	}
}

#gmap {
	min-height: 600px;
	width: 100%;
}

.maps {
	position: relative;
	z-index: 1;
}

.section-reservations {
	padding: 40px 0 80px;
	background-color: var(--cta-color);

	.row {
		max-width: 640px;
		color: var(--primary-background-color);
	}

	h1,
	h2,
	h3 {
		color: var(--primary-background-color);
	}

	h2:not(.subtitle) {
		border-top: 1px solid var(--primary-background-color);
		border-bottom: 1px solid var(--primary-background-color);
		padding: 20px 0;
		margin: 30px 0;
	}

	:deep(.button),
	.button {
		border: 1px solid var(--primary-background-color);
	}
}

.border-lines {
	width: 100%;
	height: 15px;
	margin: 0 0 60px;
	background: url('~/assets/images/border-pattern.png') repeat-x center center;

	&:last-child {
		margin: 60px 0 0;
	}
}

.section-promotion {
	background: var(--primary-background-color);
	margin: 75px auto;
	padding: 115px 0;
	overflow: auto;
	clip-path: inset(0);
	position: relative;
	max-width: var(--row-width);
	width: 98%;
	border-radius: 30px;

	h2 {
		border-top: 1px solid var(--primary-background-color);
		border-bottom: 1px solid var(--primary-background-color);
		color: var(--primary-background-color);
		text-transform: uppercase;
		padding: 10px 0;

		&.subtitle {
			border: none;
			margin: 0 0 20px;
			padding: 0;
		}
	}

	&::after {
		background: rgb(0 0 0 / 45%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: var(--primary-background-color);
		overflow: hidden;
		max-width: 700px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;
		color: var(--primary-background-color);

		h2:not(.subtitle),
		h3:not(.subtitle) {
			border-top: 1px solid var(--primary-background-color);
			border-bottom: 1px solid var(--primary-background-color);
			color: var(--primary-background-color);
			text-transform: uppercase;
			padding: 10px 0;
			width: auto;
			display: inline-block;
		}

		.subtitle {
			color: var(--primary-background-color);
			border: none;
			margin: 0 0 20px;
			padding: 0;
		}
	}
}

.section-contact {
	color: var(--cta-color);

	h2:not(.subtitle) {
		border-top: 1px solid var(--cta-color);
		border-bottom: 1px solid var(--cta-color);
		padding: 20px 0;
		margin: 30px auto;
		width: auto;
		display: inline-block;
	}

	.row:first-child {
		padding-bottom: 50px;
	}

	.row {
		position: relative;
	}

	.column3 {
		position: absolute;
		left: 50px;
		top: calc(50% - 160px);
		background: var(--primary-background-color);
		box-shadow: 0 24px 25px 0 rgba(0 0 0 / 15%);
		border-radius: 30px;
		padding: 50px 40px;
		z-index: 3;
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1200px) {
	.section-contact {
		.column3 {
			width: 40%;
		}
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		grid-template-columns: 100%;

		.intro-text {
			padding: 0 10px;
		}
	}

	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.section-promotions {
		padding: 125px 25px;
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 30px;
		}
	}

	.section-contact {
		padding: 75px 0 0;

		.row:last-child {
			display: flex;
			flex-flow: column-reverse wrap;
		}

		.column3 {
			width: 86%;
			max-width: 400px;
			position: relative;
			margin: -50px auto 50px;
			left: auto;
			bottom: auto;
			top: auto;
			padding: 30px;
		}
	}
}
</style>
